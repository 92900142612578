import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    UserCapabilitiesState,
    userCapabilitiesFeatureKey,
} from './user-capabilities.reducer';

const selectUserCapabilitiesFeature =
    createFeatureSelector<UserCapabilitiesState>(userCapabilitiesFeatureKey);

export const selectUserCapabilities = createSelector(
    selectUserCapabilitiesFeature,
    (state) => state.capabilities,
);
