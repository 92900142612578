import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { fetchUserCapabilities } from '../store/user-capabilities/user-capabilities.actions';
import { selectUserCapabilities } from '../store/user-capabilities/user-capabilities.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserCapabilitiesService {
    private readonly store = inject(Store);

    readonly userCapabilities$ = this.store.select(selectUserCapabilities);

    readonly userCapabilities = toSignal(this.userCapabilities$, {
        requireSync: true,
    });

    fetchUserCapabilities(): void {
        this.store.dispatch(fetchUserCapabilities());
    }
}
